import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

Elm.Main.init({
  node: document.getElementById('root')
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const db = firebase.firestore();
const messages = db.collection('messages');

const onMidiSuccess = function(midiAccess) {
  midiAccess.inputs.forEach(channel => {
    channel.onmidimessage = handleIncomingMidi;
  });

  midiAccess.outputs.forEach(channel => {
    if (channel.name !== "minilogue SOUND") {
      return;
    }

    messages.onSnapshot(snapshot => {
      snapshot.forEach(message => {
        messages.doc(message.id).delete();
        channel.send(message.data().payload);
      });
    });
  });
};

const onMidiFailure = function(resp) {
  const errorMsg = document.createElement('pre');
  errorMsg.innerHTML = JSON.stringify(resp);
  document.body.appendChild(errorMsg);
};

const handleIncomingMidi = function(event) {
  let note, velocity, knob, value;
  const [eventCode, ...payload] = event.data;

  switch (eventCode) {
    case 248: // Clock
      return;
    case 144: // Key Down
      [note, velocity] = payload;
      console.log("KeyDown", { note, velocity });
      return;
    case 128: // Key Up
      [note, velocity] = payload;
      console.log("KeyUp", { note, velocity });
      return;
    case 176: // Knob
      [knob, value] = payload;
      console.log("Knob", { knob, value });
      return;
    default:
      console.log(eventCode, payload);
  }
};

const addWhiteKey = function(container, note) {
  addButton(container, note, 'white');
};

const addBlackKey = function(container, note) {
  addButton(container, note, 'black');
};

const addButton = function(container, note, color) {
  const button = document.createElement('button');
  button.className = `key key--${color}`;

  button.addEventListener('mousedown', event => {
    messages.add({
      payload: [144, note, 64],
    });
  });

  button.addEventListener('mouseup', event => {
    messages.add({
      payload: [128, note, 64],
    });
  });

  container.appendChild(button)
};

const addOctave = function(container, startNote) {
  const octave = document.createElement('div');
  octave.className = 'octave'

  addWhiteKey(octave, startNote);
    addBlackKey(octave, startNote + 1);
  addWhiteKey(octave, startNote + 2);
    addBlackKey(octave, startNote + 3);
  addWhiteKey(octave, startNote + 4);

  addWhiteKey(octave, startNote + 5);
    addBlackKey(octave, startNote + 6);
  addWhiteKey(octave, startNote + 7);
    addBlackKey(octave, startNote + 8);
  addWhiteKey(octave, startNote + 9);
    addBlackKey(octave, startNote + 10);
  addWhiteKey(octave, startNote + 11);

  container.appendChild(octave);
};

const addKeyboard = function(container) {
  const keyboard = document.createElement('div');
  keyboard.className = 'keyboard';
  addOctave(keyboard, 36);
  addOctave(keyboard, 48);
  addOctave(keyboard, 60);

  container.appendChild(keyboard);
};

const addKnob = function(container, name, id) {
  const knob = document.createElement('div');
  knob.className = 'knob';

  const label = document.createElement('label');
  label.innerHTML = name;
  label.setAttribute('for', `knob_${id}`);

  const input = document.createElement('input');
  input.className = 'knob'
  input.id = `knob_${id}`;
  input.type = 'range';
  input.min = 0;
  input.max = 127;
  input.value = 63;
  input.addEventListener('input', event => {
    const { valueAsNumber: value } = event.target;
    messages.add({
      payload: [176, id, value],
    });
  })

  knob.appendChild(label);
  knob.appendChild(input);
  container.appendChild(knob);
};

const addWave = function(container, vco, knob, label) {
  const wave = document.createElement('div');
  wave.className = 'wave';

  addRadio(wave, `${label} Saw`, vco, knob, 127);
  addRadio(wave, `${label} Triangle`, vco, knob, 64);
  addRadio(wave, `${label} Square`, vco, knob, 0);

  container.appendChild(wave);

  function addRadio(container, label, vco, knob, shape) {
    const div = document.createElement('div');
    const inputSaw = document.createElement('input');
    inputSaw.type = 'radio';
    inputSaw.id = `${vco}_${shape}`;
    inputSaw.name = vco;
    inputSaw.value = shape;

    inputSaw.addEventListener('input', event => {
      const value = Number(event.target.value);
      messages.add({
        payload: [176, knob, value],
      });
    });

    const labelSaw = document.createElement('label');
    labelSaw.innerHTML = label;
    labelSaw.setAttribute('for', `${vco}_${shape}`);

    div.appendChild(inputSaw);
    div.appendChild(labelSaw);

    container.appendChild(div);
  }
}

const addShift= function(container, vco, knob, label) {
  const shift = document.createElement('div');
  shift.className = 'shift';

  addRadio(shift, `${label} shift 1`, vco, knob, 127);
  addRadio(shift, `${label} shift 2`, vco, knob, 84);
  addRadio(shift, `${label} shift 3`, vco, knob, 42);
  addRadio(shift, `${label} shift 4`, vco, knob, 0);

  container.appendChild(shift);

  function addRadio(container, label, vco, knob, shift) {
    const div = document.createElement('div');
    const input = document.createElement('input');
    input.type = 'radio';
    input.id = `${vco}_shift_${shift}`;
    input.name = `shift_${vco}`;
    input.value = shift;

    input.addEventListener('input', event => {
      const value = Number(event.target.value);
      messages.add({
        payload: [176, knob, value],
      });
    });

    const labelEl = document.createElement('label');
    labelEl.innerHTML = label;
    labelEl.setAttribute('for', `${vco}_shift_${shift}`);

    div.appendChild(input);
    div.appendChild(labelEl);

    container.appendChild(div);
  }
}

const addSeparator = function(container) {
  const separator = document.createElement('hr');
  container.appendChild(separator);
}

addKeyboard(document.body);
addSeparator(document.body);
addKnob(document.body, 'Filter', 43);
addKnob(document.body, 'Hi pass cutoff', 29);
addKnob(document.body, 'Noise', 33);
addSeparator(document.body);
addKnob(document.body, 'Mixer VCO1', 39);
addKnob(document.body, 'Mixer VCO2', 40);
addSeparator(document.body);
addKnob(document.body, 'VCO1 Attack', 16);
addKnob(document.body, 'VCO1 Decay', 17);
addKnob(document.body, 'VCO1 Sustain', 18);
addKnob(document.body, 'VCO1 Release', 19);
addSeparator(document.body);
addKnob(document.body, 'VCO2 Attack', 20);
addKnob(document.body, 'VCO2 Decay', 21);
addKnob(document.body, 'VCO2 Sustain', 22);
addKnob(document.body, 'VCO2 Release', 23);
addSeparator(document.body);
addWave(document.body, 'vco1', 50, 'VCO1');
addSeparator(document.body);
addShift(document.body, 'vco1', 48, 'VCO1');
addSeparator(document.body);
addWave(document.body, 'vco2', 51, 'VCO2');
addSeparator(document.body);
addShift(document.body, 'vco2', 49, 'VCO2');
addSeparator(document.body);
addKnob(document.body, 'VCO1 Pitch', 34);
addKnob(document.body, 'VCO1 Shape', 36);
addSeparator(document.body);
addKnob(document.body, 'VCO2 Pitch', 35);
addKnob(document.body, 'VCO2 Shape', 37);
addSeparator(document.body);

navigator.requestMIDIAccess().then(onMidiSuccess, onMidiFailure);
